.ThumbnailPage{
  margin: 0px 5%;
}
.keuzeLijst{
  display: flex;
  justify-content: space-evenly;
}
.keuzeGroep{
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  width: 100%;
  margin: 10px;
}
.keuzeGroep > *{
  margin: auto;
}
.keuzeGroep> input,.keuzeGroep > p{
  width:100%;
  margin: 0px;
  margin-top: 5px;
}
.titleInput{
  border-radius: 5px;
  border: darkgray 2px solid;
  padding: 5px;
}
.changeButtons > i{
  user-select: none;
  background-color: dodgerblue;
  padding: 5px;
  border-radius: 5px;
  color: white;
  margin: 5px;
  cursor: pointer;
}

.stepButtons{
  margin: 10px;
}
.imageChanger{
  display: flex;
  display: -ms-flexbox;
  justify-content: space-evenly;
  -ms-flex-pack: center;

}

.changeGroup{
  text-align: center;
  display: flex;
  justify-items: center;
  flex-direction: column;
}

body{
  background-color: dimgray;
}

.uitleg{
  padding: 15px;
  font-size: 20px;
  font-weight: bolder;
  background-color: #464646;
  text-align: center;
  border-radius: 5px;
  margin: 10px;
}


header{
  margin: 5px;
}
.editFields{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
}

.editFields >label{
  margin: 10px;
}
.downloadButton{
  font-size: 20px;
  margin: 10px;
  display: flex;
  justify-content: center;
}
.stepButtons {
  display: flex;
  justify-content: space-evenly;
  min-width: 1px;
}
canvas{
  display: block;
  justify-self: center;

  margin: 10px auto 50px auto;
}
button{
  color:white;
  background: dodgerblue;
  border-radius: 5px ;
  padding: 15px;
  border: none;
}
p,label,ol{
  color: white;
  margin: 0;
  font-family: "Segoe UI";
}
ol{
  padding: 0px 15px;
}
